import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "assets/svgs/Flance-Logo__Full.svg";
import Notif1 from "assets/images/notif1.png";
import Notif2 from "assets/images/notif2.png";
import PlanCard from "assets/images/plan-card.png";
import { createBrowserHistory } from "history";

import bestBody from "assets/images/gym-logos/bestBody.png";
import cbaGym from "assets/images/gym-logos/cbaGym.png";
import donsylvester from "assets/images/gym-logos/donSylvester.png";
import doris from "assets/images/gym-logos/doris.png";
import fitnessArcade from "assets/images/gym-logos/fitnessArcade.png";
import fitnessOption from "assets/images/gym-logos/fitnessOption.png";
import fresbon from "assets/images/gym-logos/frebson.png";
import greenLeaf from "assets/images/gym-logos/greenLeaf.png";
import mancave from "assets/images/gym-logos/manCave.png";
import sansFrontier from "assets/images/gym-logos/sansFrontier.png";
import topfit from "assets/images/gym-logos/topfit.png";
import tripleA from "assets/images/gym-logos/tripleA.png";
import LogoSlider from "components/Slider";
import AuthPattern from "assets/svgs/auth-pattern.svg";
import AuthEllipse from "assets/svgs/auth-ellipse.svg";
import { AuthCarousel } from "components/AuthCarousel";

const AuthLayout = ({ children }) => {
    let search = createBrowserHistory().location.search;
    let params = new URLSearchParams(search);
    let partner = params.get("partner");
    let product = params.get("product");

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        // This was added to delay the rendering of the carousel as it was causing a flicker
        setTimeout(() => {
            setIsMounted(true);
        }, 100);
    }, []);

    return (
        <div className={`auth`}>
            <div className="auth__aside">
                <div className="auth__logo">
                    {partner === "bolt" ? (
                        <a
                            href={`${process.env.REACT_APP_FLANCEWEBSITE}/deals/bolt`}
                        >
                            <img src={Logo} alt="Flance Logo" />
                        </a>
                    ) : partner === "pagesbydami" ? (
                        <a
                            href={`${process.env.REACT_APP_FLANCEWEBSITE}/communities/pagesbydami`}
                        >
                            <img src={Logo} alt="Flance Logo" />
                        </a>
                    ) : partner === "gym" ? (
                        <a
                            href={`${process.env.REACT_APP_FLANCEWEBSITE}/products/gym`}
                        >
                            <img src={Logo} alt="Flance Logo" />
                        </a>
                    ) : (
                        <a href={`${process.env.REACT_APP_FLANCEWEBSITE}`}>
                            <img src={Logo} alt="Flance Logo" />
                        </a>
                    )}
                </div>
                <div className="auth__aside--content">
                    {product === "gym" ? (
                        <>
                            <>
                                <div className="auth__aside--header">
                                    <h1>
                                        Find and book a top gym near you
                                        starting at N15k/month.
                                    </h1>
                                    <p>
                                        With Flance, you get access to a massive
                                        network of gyms, studios, and fitness
                                        centers.
                                    </p>
                                </div>
                                <div className="auth__aside--images">
                                    <div className="carousel__container primary">
                                        <div className="mini__carousel">
                                            <LogoSlider
                                                images={[
                                                    cbaGym,
                                                    bestBody,
                                                    donsylvester,
                                                    doris,
                                                    fitnessArcade,
                                                    fitnessOption,
                                                    fresbon,
                                                    greenLeaf,
                                                    mancave,
                                                    sansFrontier,
                                                    topfit,
                                                    tripleA,
                                                ]}
                                            />
                                            <LogoSlider
                                                images={[
                                                    sansFrontier,
                                                    topfit,
                                                    tripleA,
                                                    cbaGym,
                                                    bestBody,
                                                    donsylvester,
                                                    doris,
                                                    fitnessArcade,
                                                    fitnessOption,
                                                    fresbon,
                                                    greenLeaf,
                                                    mancave,
                                                ]}
                                            />
                                            <LogoSlider
                                                images={[
                                                    fresbon,
                                                    greenLeaf,
                                                    mancave,
                                                    sansFrontier,
                                                    topfit,
                                                    tripleA,
                                                    cbaGym,
                                                    bestBody,
                                                    donsylvester,
                                                    doris,
                                                    fitnessArcade,
                                                    fitnessOption,
                                                ]}
                                            />
                                            <LogoSlider
                                                images={[
                                                    doris,
                                                    fitnessArcade,
                                                    fitnessOption,
                                                    fresbon,
                                                    greenLeaf,
                                                    mancave,
                                                    sansFrontier,
                                                    topfit,
                                                    tripleA,
                                                    cbaGym,
                                                    bestBody,
                                                    donsylvester,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        </>
                    ) : (
                        <>
                            {isMounted && (
                                <div className="auth__aside-slider-container">
                                    <AuthCarousel />
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="auth__aside-ellipse-wrap">
                    <img
                        src={AuthEllipse}
                        alt=""
                        className="auth__aside-ellipse"
                    />
                </div>
                <img src={AuthPattern} alt="" className="auth__aside-pattern" />
            </div>
            <div className="auth__form">{children}</div>
        </div>
    );
};

export default AuthLayout;
